<template>
  <div>
    <c-table
      ref="table2"
      title="회의 목록"
      :columns="grid2.columns"
      :gridHeight="grid2.height"
      :data="assessPlan.conferences"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="ramAssessmentConferenceId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" :showLoading="false" label="추가" icon="add" @btnClicked="addConference" />
          <c-btn 
            v-if="editable&&!disabled" 
            :disabled="!assessPlan.conferences || assessPlan.conferences.length === 0"
            label="삭제" 
            icon="remove" 
            @btnClicked="deleteConference" />
          <c-btn 
            v-if="editable && !disabled" 
            :disabled="!assessPlan.conferences || assessPlan.conferences.length === 0"
            :isSubmit="isSaveConference"
            :url="saveConferenceUrl"
            :param="assessPlan.conferences"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveAssessConference"
            @btnCallback="saveConferenceCallback" />
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      class="q-mt-sm"
      ref="table3"
      title="관련자료"
      :columns="grid3.columns"
      :gridHeight="grid3.height"
      :data="assessPlan.relatedDocuments"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&!disabled"
      selection="multiple"
      rowKey="ramRelatedDocumentId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable && !disabled" label="기타-추가" icon="add" @btnClicked="addEtc"/>
          <c-btn v-if="editable && !disabled" label="추가" icon="add" @btnClicked="addRelateDocument"/>
          <c-btn 
            v-if="editable&&!disabled" 
            :disabled="!assessPlan.relatedDocuments || assessPlan.relatedDocuments.length === 0"
            label="삭제" 
            icon="remove" 
            @btnClicked="deleteRelateDocument" />
          <c-btn 
            v-if="editable && !disabled" 
            :disabled="!assessPlan.relatedDocuments || assessPlan.relatedDocuments.length === 0"
            :isSubmit="isSaveRelateDocument"
            :url="saveRelateDocumentUrl"
            :param="assessPlan.relatedDocuments"
            mappingType="PUT"
            label="저장" 
            icon="save"
            @beforeAction="saveRelateDocument"
            @btnCallback="saveRelateDocumentCallback" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'charm-analysis-team',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        ramStepCd: '',
        teams: [], // 평가팀 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
      }),
    },
    updateBtnData: {
      type: Object,
      default: () => ({
        flag: false,
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid2: {
        columns: [
          {
            name: 'conferenceName',
            field: 'conferenceName',
            label: '회의명',
            align: 'left',
            style: 'width:25%',
            type: 'text',
            sortable: false,
            required: true,
          },
          {
            name: 'conferenceDate',
            field: 'conferenceDate',
            label: '회의일',
            align: 'center',
            style: 'width:10%',
            type: 'date',
            sortable: false,
            required: true,
          },
          {
            name: 'conferenceContents',
            field: 'conferenceContents',
            label: '회의내용',
            align: 'left',
            style: 'width:45%',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'attendees',
            field: 'attendees',
            label: '참석자',
            align: 'center',
            style: 'width:10%',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '첨부파일',
            align: 'center',
            style: 'width:10%',
            type: 'attach',
            taskClassCd: 'RSA_CONFERENCE',
            keyText: 'ramAssessmentConferenceId',
            sortable: false,
          },
        ],
        data: [],
        height: '350px'
      },
      grid3: {
        columns: [
          {
            name: 'ramDocumentTypeName',
            field: 'ramDocumentTypeName',
            label: '분류',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
          {
            name: 'documentTitle',
            field: 'documentTitle',
            description: 'documentSubTitle',
            label: '제목',
            align: 'left',
            style: 'width:40%',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자료명',
            align: 'left',
            style: 'width:40%',
            type: 'text',
            sortable: false,
            required: true,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '첨부파일',
            align: 'center',
            style: 'width:10%',
            type: 'attach',
            taskClassCd: 'RSA_RELATE_INFO',
            keyText: 'ramRelatedDocumentId',
            sortable: false,
          },
        ],
        height: '350px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      conferenceListUrl: '',
      saveConferenceUrl: '',
      deleteConferenceUrl: '',
      relateDocumentListUrl: '',
      saveRelateDocumentUrl: '',
      deleteRelateDocumentUrl: '',
      isSaveConference: false,
      isSaveRelateDocument: false,
    };
  },
  computed: {
    disabled() {
      return this.assessPlan.ramStepCd !== 'RRS0000001' && this.assessPlan.ramStepCd && !this.updateBtnData.flag
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.conferenceListUrl = selectConfig.ram.assessConference.list.url;
      this.relateDocumentListUrl = selectConfig.ram.relatedDocument.list.url;
      this.saveConferenceUrl = transactionConfig.ram.assessConference.update.url;
      this.saveRelateDocumentUrl = transactionConfig.ram.relatedDocument.update.url;
      this.deleteConferenceUrl = transactionConfig.ram.assessConference.delete.url;
      this.deleteRelateDocumentUrl = transactionConfig.ram.relatedDocument.delete.url;
      // code setting
      // list setting
    },
    getConferences() {
      this.$http.url = this.conferenceListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.assessPlan.conferences = _result.data
      },);
    },
    getRelateDocuments() {
      this.$http.url = this.relateDocumentListUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      }
      this.$http.request((_result) => {
        this.assessPlan.relatedDocuments = _result.data
      },);
    },
    addConference() {
      this.assessPlan.conferences.splice(0, 0, {
        ramAssessmentConferenceId: uid(),
        conferenceName: '',
        conferenceDate: this.$comm.getToday(),
        conferenceContents: '',
        attendees: '',
        editFlag: 'C', regUserId: this.$store.getters.user.userId,
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId
      })
    },
    deleteConference() {
      let selectData = this.$refs['table2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteConferenceUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          
          this.$_.forEach(selectData, item => {
            this.assessPlan.conferences = this.$_.reject(this.assessPlan.conferences, item);
          })
          this.$refs['table2'].$refs['compo-table'].clearSelection();
          // this.getConferences();
        },);
      }
    },
    addRelateDocument() {
      this.popupOptions.title = '관련자료 검색';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/charm/plan/charmRelationDocu.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeRelateDocumentPopup;
    },
    closeRelateDocumentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.assessPlan.relatedDocuments, { documentId: item.documentId, ramDocumentTypeCd: item.ramDocumentTypeCd }) === -1) {
            this.assessPlan.relatedDocuments.splice(0, 0, { 
              ramRelatedDocumentId: uid(),  // 관련자료 일련번호
              ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,  // 평가 일련번호
              ramDocumentTypeCd: item.ramDocumentTypeCd,  // 문서 분류 코드
              ramDocumentTypeName: item.ramDocumentTypeName,  // 문서 분류 코드
              documentId: item.documentId,  // 문서 일련번호
              documentTitle: item.documentTitle,  // 문서 제목
              documentSubTitle: item.documentSubTitle,  // 문서 부제목
              materialName: item.documentTitle,  // 자료명
              editFlag: 'C', regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    addEtc() {
      if (!this.assessPlan.relatedDocuments) this.assessPlan.relatedDocuments = [];
      this.assessPlan.relatedDocuments.splice(0, 0, { 
        ramRelatedDocumentId: uid(),  // 관련자료 일련번호
        ramRiskAssessmentPlanId: this.assessPlan.ramRiskAssessmentPlanId,  // 평가 일련번호
        ramDocumentTypeCd: 'RDT0000020',  // 문서 분류 코드
        ramDocumentTypeName: '기타',  // 문서 분류 코드
        documentId: '',  // 문서 일련번호
        documentTitle: '',  // 문서 제목
        documentSubTitle: '',  // 문서 부제목
        materialName: '',  // 자료명
        editFlag: 'C', regUserId: this.$store.getters.user.userId,
      });
    },
    saveAssessConference() {
      if (this.$comm.validTable(this.grid2.columns, this.assessPlan.conferences)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.assessPlan.conferences, item => {
              item.chgUserId = this.$store.getters.user.userId
            })

            this.isSaveConference = !this.isSaveConference
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveConferenceCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getConferences();
    },
    saveRelateDocument() {
      if (this.$comm.validTable(this.grid3.columns, this.assessPlan.relatedDocuments)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSaveRelateDocument = !this.isSaveRelateDocument
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveRelateDocumentCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getRelateDocuments();
    },
    deleteRelateDocument() {
      let selectData = this.$refs['table3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteRelateDocumentUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          
          this.$_.forEach(selectData, item => {
            this.assessPlan.relatedDocuments = this.$_.reject(this.assessPlan.relatedDocuments, item);
          })
          this.$refs['table3'].$refs['compo-table'].clearSelection();
          // this.getRelateDocuments();
        },);
      }
    },
  }
};
</script>
 